import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { createScan } from "../../hooks/Scans";
import { toastSuccess } from "../../services/Toast";
import { Box, Container } from "@mui/system";
import { Search as SearchIcon } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentContainer from "../../common/ContentContainer";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Home: React.FC = () => {
  const [url, setUrl] = useState<string>("");
  const [scanType, setScanType] = useState<string>("full");

  const { isLoading, mutate } = useMutation(async () => {
    const {
      data: { scanId },
    } = await createScan(url.trim(), scanType);
    toastSuccess(`Scan Initiated Successfully!\nScan ID: ${scanId}`);
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContentContainer>
          <Typography component="h1" variant="h5">
            Everything starts with a scan...
          </Typography>

          <Box
            component="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              mutate();
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="URL"
              name="url"
              autoComplete="email"
              autoFocus
              value={url}
              onChange={(e) => setUrl(e.target.value || "")}
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth>
              <FormLabel id="scanType-select">Scan Type</FormLabel>
              <RadioGroup
                row
                defaultValue={"full"}
                aria-labelledby="scanType-select"
                name="row-radio-buttons-group"
                onChange={(e) => setScanType(e.target.value)}
                sx={{ justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value="enum"
                  control={<Radio />}
                  label="Enum"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="single"
                  control={<Radio />}
                  label="Single"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="full"
                  control={<Radio />}
                  label="Full"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SearchIcon />}
              loading={isLoading}
            >
              Launch scan
            </LoadingButton>
          </Box>
        </ContentContainer>
      </Box>
    </Container>
  );
};

export default Home;
