import React, { useCallback, useEffect, useState } from "react";
import { deleteScan, getScans, stopScan } from "../../hooks/Scans";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import IScan from "../../interfaces/scans";
import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import ListItem from "@mui/material/ListItem";
import CheckIcon from "@mui/icons-material/Check";
import StopIcon from "@mui/icons-material/Stop";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import { toastSuccess } from "../../services/Toast";
import { Box, Container } from "@mui/material";
import ContentContainer from "../../common/ContentContainer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import Backdrop from '@mui/material/Backdrop';

// interface IScanResults {
//   Items: IScan[];
//   Count?: number;
//   ScannedCount?: number;
// }

const Scans: React.FC = () => {
  const [scans, setScans] = useState<IScan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [isHoveringDeleteId, setHoveringDeleteId] = useState<string | null>("");
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);

  const [openStopId, setOpenStopId] = useState<string | null>("");
  const handleCloseStop = () => setOpenStopId(null);
  const handleOpenStop = (id: string) => {
    setOpenStopId(id);
  };

  const [openDeleteId, setOpenDeleteId] = useState<string | null>("");
  const handleCloseDelete = () => setOpenDeleteId(null);
  const handleOpenDelete = (id: string) => {
    console.log(id)
    setOpenDeleteId(id);
  };

  const disabledIconStatus = [
    "Completed",
    "Finished",
    "Stopped",
    "Failed",
    "Starting...",
  ];
  const doesNotShowDateStatus = ["Completed", "Finished", "Stopped", "Failed"];

  const convertScans = (scans: IScan[]) => {
    const sortedScans = scans
      .sort((x: IScan, y: IScan) => Number(x.Timestamp) - Number(y.Timestamp))
      .reverse();
    const convertedScans = sortedScans.map((scan: IScan) => {
      const createdAt = new Date(Number(scan.Timestamp)).toLocaleString(
        "pt-BR",
        { timeZone: "America/Sao_Paulo" }
      );
      const convertedCreatedAt = createdAt.replace(" ", ", ");

      return {
        ...scan,
        CreatedAt: convertedCreatedAt,
      };
    });

    return convertedScans;
  };

  useEffect(() => {
    setLoading(true);

    const fetchScans = async () => {
      const response = await getScans();
      const convertedScans = convertScans(response.data.Items);
      setScans(convertedScans);
    };
    fetchScans().catch((err) => console.log(err));

    setLoading(false);
  }, []);

  const goToScan = (scanId: string) => {
    navigate(`/scans/${scanId}/subdomains`);
  };

  const handleRefresh = useCallback(async () => {
    setLoading(true);

    const response = await getScans();
    const convertedScans = convertScans(response.data.Items);
    setScans(convertedScans);

    setLoading(false);
  }, []);

  const handleStopScan = async (scanId: string, timestamp: string) => {
    try {
      const firstToastId = toast(`Stopping ${scanId}...`);
      setOpenStopId(null);
      setIsBackdropOpen(true);

      await stopScan(scanId, timestamp);

      setIsBackdropOpen(false);
      toast.dismiss(firstToastId);
      toastSuccess(`Scan ${scanId} was stopped successfully!`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteScan = async (scanId: string, timestamp: string) => {
    try {
      const firstToastId = toast(`Deleting ${scanId}...`);
      setOpenDeleteId(null);
      setIsBackdropOpen(true);

      await deleteScan(scanId, timestamp);

      setIsBackdropOpen(false);
      toast.dismiss(firstToastId);
      toastSuccess(`Scan ${scanId} was deleted successfully!`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleScanStatusIcon = (scanStatus: string) => {
    if (scanStatus === "Completed" || scanStatus === "Finished") {
      return <CheckIcon sx={{ color: "green" }} />;
    } else if (scanStatus === "Stopped" || scanStatus === "Failed") {
      return <CloseIcon sx={{ color: "red", opacity: 0.2 }} />;
    } else {
      return <StopIcon />;
    }
  };

  const handleScanStatusIconButton = (scan: IScan) => {
    if (doesNotShowDateStatus.includes(scan.Status)) {
      return (
        <IconButton
          edge="end"
          aria-label="stop"
          color="error"
          onClick={() => handleOpenDelete(scan.ScanId)}
          onMouseOver={() => setHoveringDeleteId(scan.ScanId)}
          onMouseOut={() => setHoveringDeleteId(null)}
        >
          {isHoveringDeleteId === scan.ScanId ? <DeleteIcon sx={{ color: "red" }} /> : handleScanStatusIcon(scan.Status)}
        </IconButton>
      )
    } else {
      return (
        <IconButton
          edge="end"
          aria-label="stop"
          color="error"
          onClick={() => handleOpenStop(scan.ScanId)}
          disabled={disabledIconStatus.includes(
            scan.Status
          )}
        >
          {handleScanStatusIcon(scan.Status)}
        </IconButton>
      );
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ContentContainer>
            <Typography component="h1" variant="h5">
              Scans
              <IconButton
                aria-label="refresh"
                onClick={() => handleRefresh()}
                sx={{
                  ml: 1,
                }}
              >
                <SyncIcon sx={{ color: "white" }} />
              </IconButton>
            </Typography>

            <div
              className="justify-content-center w-25 mt-5"
              style={{ position: "relative", zIndex: 1, minWidth: "500px" }}
            >
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <>
                  <Typography align="center" color={"text.disabled"}>
                    {scans.length} scans
                  </Typography>

                  <List>
                    {scans &&
                      scans.map((scan) => (
                        <ListItem
                          key={`${scan.ScanId}-listItem`}
                          divider
                          secondaryAction={handleScanStatusIconButton(scan)}
                        >
                          <Dialog
                            key={`${scan.ScanId}-dialog`}
                            open={openStopId === scan.ScanId}
                            onClose={handleCloseStop}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {`Stop scan on ${scan.ScanUrl}?`}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are you sure you want to stop this scan? By
                                doing so, you will not be able to resume it
                                later.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseStop}>Cancel</Button>
                              <Button
                                onClick={() =>
                                  handleStopScan(scan.ScanId, scan.Timestamp)
                                }
                                autoFocus
                              >
                                Stop
                              </Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog
                            key={`${scan.ScanId}-delete-dialog`}
                            open={openDeleteId === scan.ScanId}
                            onClose={handleCloseDelete}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {`Delete scan on ${scan.ScanUrl}?`}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this scan? By doing so, you will not be able recover it later.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseDelete}>Cancel</Button>
                              <Button
                                onClick={() =>
                                  handleDeleteScan(scan.ScanId, scan.Timestamp)
                                }
                                autoFocus
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <ListItemButton
                            key={scan.ScanId}
                            className="justify-content-center"
                            onClick={() => goToScan(scan.ScanId)}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  fontWeight={"bold"}
                                  className="mb-2"
                                  sx={{ color: "white" }}
                                >
                                  [{(scan.ScanType || "full").toUpperCase()}] {scan.ScanUrl}
                                </Typography>
                              }
                              secondary={
                                doesNotShowDateStatus.includes(scan.Status) ? (
                                  <Typography
                                    sx={{ color: "white" }}
                                    fontSize={"small"}
                                  >
                                    {scan.CreatedAt}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ color: "white" }}
                                    fontSize={"small"}
                                  >
                                    {scan.Status}
                                  </Typography>
                                )
                              }
                            ></ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    <ListItemButton
                      key={"more"}
                      className="justify-content-center"
                    >
                      <ListItemIcon>
                        <MoreHorizIcon color="primary" />
                      </ListItemIcon>
                    </ListItemButton>
                  </List>
                </>
              )}

              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isBackdropOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </ContentContainer>
        </Box>
      </Container>
    </>
  );
};

export default Scans;
