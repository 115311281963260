import { Auth } from "aws-amplify";

export const getAuthHeader = async () => {

    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();

    const config = {
        headers: {
            Authorization: jwt
        }
    };

    return config;
}