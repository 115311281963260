import { getAuthHeader } from "../services/GetAuth";
import Api from "../services/HakMachineApi";

export const launchMachine = async (machineName: string) => {
  const config = await getAuthHeader();

  return Api.post("/deploy", {
    machineName: machineName,
    action: "launch",
  }, config)
}

export const getMachines = async () => {
  const config = await getAuthHeader();

  return Api.post("/deploy", {
    machineName: "list",
    action: "list"
  }, config)
}

export const terminateMachine = async (machineName: string) => {
  const config = await getAuthHeader();

  return Api.post("/deploy", {
    machineName: machineName,
    action: "terminate"
  }, config)
}