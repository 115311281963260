import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { CardActionArea, Tab, Tabs } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { getInfos, getVulns } from "../../hooks/Infos";
import { useParams } from "react-router-dom";
import IInfo, {
  ITech,
  IZapVulnerability,
  IWafResult,
  INucleiVulnerability,
} from "../../interfaces/infos";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import Container from "@mui/material/Container";
import VulnerabilityDialog from "./VulnerabilityDialog";
import ContentContainer from "../../common/ContentContainer";
import Risk from "./Risk";

// interface ISubItems {
//   : ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

// interface ISubResults {
//   Items: ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

const Infos: React.FC = () => {
  const [infos, setInfos] = useState<IInfo>({} as IInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [wafResult, setWafResult] = useState<IWafResult>({} as IWafResult);
  const [isWordpress, setIsWordpress] = useState<boolean>(false);
  const [technologies, setTechnologies] = useState<ITech[]>([]);
  const [vulnerabilities, setVulnerabilities] = useState<IZapVulnerability[]>(
    []
  );
  const [nucleiVulnerabilities, setNucleiVulnerabilities] = useState<
    INucleiVulnerability[]
  >([]);

  const {
    scanId,
    subdomain,
    service: port,
  } = useParams<{ scanId: string; subdomain: string; service: string }>();
  const [tab, setTab] = useState<number>(0);

  
  const [dialogVulnerabilityName, setDialogVulnerabilityName] = useState<string>();
  const dialogVulnerability = useMemo(() => {
    if (!vulnerabilities.length) return undefined;
    if (!dialogVulnerabilityName) return undefined;
    return vulnerabilities.find(
      (vulnerability) => vulnerability.name === dialogVulnerabilityName
    );
  }, [dialogVulnerabilityName, vulnerabilities]);
  
  
  const [nucleiDialogVulnerabilityName, setNucleiDialogVulnerabilityName] = useState<string>();
  const nucleiDialogVulnerability = useMemo(() => {
    if (!nucleiVulnerabilities.length) return undefined;
    if (!nucleiDialogVulnerabilityName) return undefined;
    return nucleiVulnerabilities.find(
      (vulnerability) => vulnerability.info.name === nucleiDialogVulnerabilityName
    );
  }, [nucleiDialogVulnerabilityName, nucleiVulnerabilities]);


  // const sortNucleiVulnsBySeverity = () => {
  //   const numberedSeverityVulns = nucleiVulnerabilities.map((vulnerability) => {
  //     switch (vulnerability.info.severity) {
  //       case "high":
  //         vulnerability.info.severity = "4";
  //         break;
  //       case "medium":
  //         vulnerability.info.severity = "3";
  //         break;
  //       case "low":
  //         vulnerability.info.severity = "2";
  //         break;
  //       case "info":
  //         vulnerability.info.severity = "1";
  //         break;
  //       default:
  //         break;
  //     }
  //     return vulnerability;
  //   });

  //   const sortedVulns = numberedSeverityVulns.sort((a, b) => {
  //     return b.info.severity.localeCompare(a.info.severity);
  //   });

  //   const writtenSeverityVulns = sortedVulns.map((vulnerability) => {
  //     switch (vulnerability.info.severity) {
  //       case "4":
  //         vulnerability.info.severity = "High";
  //         break;
  //       case "3":
  //         vulnerability.info.severity = "Medium";
  //         break;
  //       case "2":
  //         vulnerability.info.severity = "Low";
  //         break;
  //       case "1":
  //         vulnerability.info.severity = "Info";
  //         break;
  //       default:
  //         break;
  //     }
  //     return vulnerability;
  //   });

  //   setNucleiVulnerabilities(writtenSeverityVulns);
  // };

  useEffect(() => {
    setLoading(true);

    const fetchScans = async () => {
      if (!scanId || !subdomain || !port) return;
      const response = await getInfos(scanId, subdomain, Number(port));
      const vulnsResponse = await getVulns(scanId, subdomain, Number(port));

      if (response.data.Items.length > 0) {
        setInfos(response.data.Items[0]);

        setWafResult(JSON.parse(response.data.Items[0].WafResult)[0]);

        const techsFound = JSON.parse(
          response.data.Items[0].Technologies
        ).matches.map((tech: any) => ({
          app_name: tech.app_name,
          version: tech.version,
        }));
        setTechnologies(techsFound);

        setIsWordpress(JSON.parse(response.data.Items[0].IsWordpress));
      }

      if (vulnsResponse.data.Items.length > 0) {
        setVulnerabilities(
          JSON.parse(vulnsResponse.data.Items[0].Vulnerabilities)
        );

        setNucleiVulnerabilities(
          JSON.parse(vulnsResponse.data.Items[0].NucleiVulnerabilities)
        );
        
      }
    };
    fetchScans().catch((err) => console.log(err));

    setLoading(false);
  }, [scanId, subdomain, port]);

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    if (!scanId || !subdomain || !port) return;
    const response = await getInfos(scanId, subdomain, Number(port));
    const vulnsResponse = await getVulns(scanId, subdomain, Number(port));

    if (response.data.Items.length > 0) {
      setInfos(response.data.Items[0]);

      setWafResult(JSON.parse(response.data.Items[0].WafResult)[0]);

      const techsFound = JSON.parse(
        response.data.Items[0].Technologies
      ).matches.map((tech: any) => ({
        app_name: tech.app_name,
        version: tech.version,
      }));
      setTechnologies(techsFound);

      setIsWordpress(JSON.parse(response.data.Items[0].IsWordpress));
    }

    if (vulnsResponse.data.Items.length > 0) {
      setVulnerabilities(
        JSON.parse(vulnsResponse.data.Items[0].Vulnerabilities)
      );

      setNucleiVulnerabilities(
        JSON.parse(vulnsResponse.data.Items[0].NucleiVulnerabilities)
      );
    }

    setLoading(false);
  }, [scanId, subdomain, port]);

  return (
    <>
      <Container component="main" maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              marginBottom: 2,
            }}
          >
            Infos of port {subdomain}:{port}:
            <IconButton aria-label="refresh" onClick={() => handleRefresh()}>
              <SyncIcon sx={{ color: "white" }} />
            </IconButton>
          </Typography>

          <div
            className="justify-content-center w-75 mt-5"
            style={{ position: "relative", zIndex: 1, minWidth: "500px" }}
          >
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <Box sx={{ flexGrow: 1 }}>
                <ContentContainer>
                  <Tabs
                    value={tab}
                    onChange={(e, newValue) => setTab(newValue)}
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    <Tab label="Vulnerabilities" />
                    <Tab label="Nuclei" />
                    <Tab label="WAF" />
                    <Tab label="Technologies" />
                  </Tabs>

                  {tab === 0 && (
                    <>
                      {vulnerabilities.length > 0 && (
                        <Grid item sm={4} xs={12}>
                          {vulnerabilities.map((vulnerability) => (
                            <Card
                              variant="outlined"
                              key={vulnerability.name}
                              sx={{
                                marginBottom: 1,
                              }}
                            >
                              <CardActionArea
                                sx={{ padding: 2 }}
                                onClick={() =>
                                  setDialogVulnerabilityName(vulnerability.name)
                                }
                              >
                                <Typography variant="body1">
                                  <b>Vulnerability:</b> {vulnerability.name}
                                </Typography>
                                <Typography variant="body1">
                                  <b>Risk:</b>{" "}
                                  <Risk description={vulnerability.riskdesc} />
                                </Typography>
                                <Typography variant="body1">
                                  <b>Confidence:</b> {vulnerability.confidence}
                                </Typography>
                                <Typography variant="body1">
                                  <b>Count:</b> {vulnerability.count}
                                </Typography>
                              </CardActionArea>
                            </Card>
                          ))}
                        </Grid>
                      )}
                    </>
                  )}

                  {tab === 1 && (
                    <>
                      {nucleiVulnerabilities.length > 0 && (
                        <Grid item sm={4} xs={12}>
                          {nucleiVulnerabilities.map((vulnerability) => (
                            <Card
                              variant="outlined"
                              key={vulnerability.timestamp}
                              sx={{
                                marginBottom: 1,
                              }}
                            >
                              <CardActionArea
                                sx={{ padding: 2 }}
                                onClick={() => setNucleiDialogVulnerabilityName(vulnerability.info.name)}
                              >
                                <Typography variant="body1">
                                  <b>Title:</b> {vulnerability.info.name}
                                </Typography>
                                <Typography variant="body1">
                                  <b>Risk:</b>{" "}
                                  <Risk description={vulnerability.info.severity} />
                                </Typography>
                                {vulnerability["matcher-name"] && (
                                  <Typography variant="body1">
                                    <b>Matcher name:</b>{" "}
                                    {vulnerability["matcher-name"]}
                                  </Typography>
                                )}
                                <Typography variant="body1">
                                  <b>Matched at:</b>{" "}
                                  {vulnerability["matched-at"]}
                                </Typography>
                              </CardActionArea>
                            </Card>
                          ))}
                        </Grid>
                      )}
                    </>
                  )}

                  {tab === 2 && (
                    <Grid item sm={4} xs={12}>
                      {infos.WafResult && (
                        <Card variant="outlined">
                          <CardContent sx={{ padding: 2 }}>
                            <Typography variant="body1" key={"waf-firewall"}>
                              <b>Firewall:</b> {wafResult.firewall}
                            </Typography>
                            <Typography
                              variant="body1"
                              key={"waf-manufacturer"}
                            >
                              <b>Manufacturer:</b> {wafResult.manufacturer}
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  )}

                  {tab === 3 && (
                    <>
                      {technologies.length > 0 && (
                        <Grid item sm={4} xs={12}>
                          {technologies.map((technology, index) => (
                            <Card key={technology.app_name} variant="outlined">
                              <CardActionArea sx={{ padding: 2 }}>
                                <Typography
                                  variant="body1"
                                  key={index + "-name"}
                                >
                                  <b>Technology:</b> {technology.app_name}
                                </Typography>
                                {technology.version && (
                                  <Typography
                                    variant="body1"
                                    key={index + "-version"}
                                  >
                                    <b>Version:</b> {technology.version}
                                  </Typography>
                                )}
                              </CardActionArea>
                            </Card>
                          ))}
                          {isWordpress && (
                            <Typography variant="body1">
                              <b>Technology:</b> Wordpress
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </ContentContainer>
              </Box>
            )}
          </div>
        </Box>
      </Container>

      <VulnerabilityDialog
        onClose={() => setDialogVulnerabilityName(undefined)}
        open={!!dialogVulnerabilityName}
        vulnerability={dialogVulnerability as any}
        vulnerabilityName={dialogVulnerabilityName as string}
      />

      <VulnerabilityDialog
        onClose={() => setNucleiDialogVulnerabilityName(undefined)}
        open={!!nucleiDialogVulnerabilityName}
        vulnerability={nucleiDialogVulnerability as any}
        vulnerabilityName={nucleiDialogVulnerabilityName as string}
      />
    </>
  );
};

export default Infos;
