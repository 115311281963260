import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Scans from "../pages/Scans";
import SignIn from "../pages/SignIn";
import Subs from "../pages/Subs";
import Services from "../pages/Services";
import Infos from "../pages/Infos";
import HakMachines from "../pages/HakMachines";
import UserRoute from "./UserRoute";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/">
      <Route index element={<SignIn />} />

      <Route element={<UserRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="/scans" element={<Scans />} />
        <Route path="/scans/:scanId/subdomains" element={<Subs />} />
        <Route
          path="/scans/:scanId/:subdomain/services"
          element={<Services />}
        />
        <Route
          path="/scans/:scanId/:subdomain/:service/infos"
          element={<Infos />}
        />
        <Route path="/hakmachines" element={<HakMachines />} />
      </Route>

      {/*<Route path="teams" element={<Teams />}>
                <Route path=":teamId" element={<Team />} />
                <Route path="new" element={<NewTeamForm />} />
                <Route index element={<LeagueStandings />} />
            </Route> */}
    </Route>
  </Routes>
);

export default AppRoutes;
