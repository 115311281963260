import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.hakengine.com/",
});

const unauthenticatedHandler = (error: any) => {
  if (error?.response?.status === 401) window.location.href = "/logout";
  return Promise.reject(error);
};

Api.interceptors.response.use((request) => request, unauthenticatedHandler);

export default Api;