import Api from "../services/Api";
import { getAuthHeader } from "../services/GetAuth";

export const createScan = async (url: string, scanType: string) => {
  const config = await getAuthHeader();

  return Api.post("/scans", {
    URL: url,
    action: "start",
    scanType
  }, config);
}

export const getScans = async () => {
  const config = await getAuthHeader();

  return Api.get("/scans", config)
}

export const stopScan = async (scanId: string, timestamp: string) => {
  const config = await getAuthHeader();

  return Api.post("/scans", {
    action: "stop",
    scanId,
    timestamp
  }, config)
}

export const deleteScan = async (scanId: string, timestamp: string) => {
  const config = await getAuthHeader();

  return Api.delete(`/scans?scanId=${scanId}&timestamp=${timestamp}`, config);
}