import { toast } from "react-toastify";

export function toastSuccess(message: string) {
  toast(message);
}

export function toastWarning(message: string) {
  toast.warn(message);
}

export function toastError(message: string) {
  toast.error(message);
}
