import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

interface Props {
  content: any;
}

const NestedItem: React.FC<Props> = ({ content }) => {
  if (typeof content === "string" || typeof content === "number")
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: String(content),
        }}
      ></div>
    );

  if (Array.isArray(content))
    return (
      <>
        {content.map((content) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <NestedItem content={content} />
          </div>
        ))}
      </>
    );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableBody>
          {Object.keys(content).map((key) => {
            const value = content[key];
            // eslint-disable-next-line array-callback-return
            if (!value) return;

            return (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right">
                  <NestedItem content={value} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NestedItem;
