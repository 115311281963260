import { Chip } from "@mui/material";
import React from "react";

interface Props {
  description: string;
}

const Risk: React.FC<Props> = ({ description }) => {
  if (description.toLowerCase().includes("high"))
    return <Chip variant="outlined" color="error" size="small" label="High" />;

  if (description.toLowerCase().includes("medium"))
    return (
      <Chip variant="outlined" color="warning" size="small" label="Medium" />
    );

  if (description.toLowerCase().includes("low"))
    return <Chip variant="outlined" color="success" size="small" label="Low" />;
  
  if (description.toLowerCase().includes("info"))
    return <Chip variant="outlined" size="small" label="Informational" />;

  return <>{description}</>;
};

export default Risk;
