import { Navigate, Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../../common/Sidebar";

const UserRoute = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function load() {
      setLoading(true);
      try {
        await Auth.currentAuthenticatedUser();
        setAuthenticated(true);
      } catch (err) {
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);

  if (loading) return <CircularProgress />;
  return authenticated ? (
    <Sidebar>
      <Outlet />
    </Sidebar>
  ) : (
    <Navigate to="/" />
  );
};

export default UserRoute;
