import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Form from "../../../common/Form";
import InputText from "../../../common/InputText";
import InputSubmit from "../../../common/InputSubmit";

interface Props {
  user: any;
}

const UpdateInformationForm: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const formMethods = useForm();

  const onSubmit = useCallback(
    async (data: any) => {
      if (data.password !== data.confirmPassword)
        throw new Error("Passwords must match.");

      await Auth.completeNewPassword(user, data.password, {
        email: data.email,
        phone_number: data.phone,
        name: data.name,
      });
      navigate("/home");
    },
    [navigate, user]
  );

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <InputText
        name="name"
        label="Name:"
        options={{
          required: true,
        }}
      />
      <InputText
        name="email"
        label="E-mail:"
        options={{
          required: true,
        }}
      />
      <InputText
        name="phone"
        label="Phone:"
        options={{
          required: true,
        }}
      />

      <InputText
        name="password"
        type="password"
        label="Password:"
        options={{
          required: true,
        }}
      />

      <InputText
        name="confirmPassword"
        type="password"
        label="Confirm password:"
        options={{
          required: true,
        }}
      />
      <InputSubmit title="Save" />
    </Form>
  );
};

export default UpdateInformationForm;
