import React, { FC, PropsWithChildren, useRef, useState } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

type Props = {
  formMethods: UseFormReturn<FieldValues, object>;
  onSubmit: (data: any) => Promise<any> | any;
  autoComplete?: boolean;
  [x:string]: any;
};

function getErrorMessageFromError(error: any): string {
  if (error?.response?.data?.message) return error.response.data.message;

  const errorMessage = error?.message;

  if (errorMessage === "Network Error")
    return "Não foi possível conectar-se ao servidor. Tente novamente.";

  if (errorMessage) return errorMessage;

  return "Erro desconhecido. Por favor, tente novamente.";
}

const Form: FC<PropsWithChildren<Props>> = ({
  formMethods,
  onSubmit,
  autoComplete = false,
  children,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const errorMessageRef = useRef<HTMLDivElement>(null);

  return (
    <FormProvider {...formMethods}>
      <form
        className="w-100"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setErrorMessage(undefined);
          try {
            await formMethods.handleSubmit(onSubmit)();
          } catch (error: any) {
            setErrorMessage(getErrorMessageFromError(error));
            errorMessageRef?.current?.scrollIntoView();
          }
        }}
        autoComplete={autoComplete ? "on" : "off"}
        {...props}
      >
        {children}
        {!!errorMessage && (
          <div className="alert alert-danger mt-3" ref={errorMessageRef}>
            <p className="text-danger m-0">{errorMessage}</p>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
