import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Form from "../../common/Form";
import InputLaunchSubmit from "../../common/InputLaunchSubmit";
import InputText from "../../common/InputText";
import { launchMachine, getMachines, terminateMachine } from "../../hooks/HakMachines";
import { toastSuccess } from "../../services/Toast";
import IMachine from "../../interfaces/machines";

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import Container from "@mui/material/Container";
import ContentContainer from "../../common/ContentContainer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

const HakMachines: React.FC = () => {
  const formMethods = useForm();
  const [machines, setMachines] = useState<IMachine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  
  const [openTerminateId, setOpenTerminateId] = useState<string | null>("");

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  
  const handleCloseTerminate = () => setOpenTerminateId(null);
  const handleOpenTerminate = (id: string) => {
    setOpenTerminateId(id);
  };

  useEffect(() => {
    setLoading(true);

    const fetchMachines = async () => {
      const response = await getMachines();
      setMachines(response.data.machines);
    };
    fetchMachines().catch((err) => console.log(err));

    setLoading(false);

  }, []);

  const handleRefresh = useCallback(async () => {
    setLoading(true);

    const response = await getMachines();
    setMachines(response.data.machines);

    setLoading(false);
  }, []);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const firstToastId = toast("Launching new machine...")

        const parsedMachineName = data.machine_name.trim();
        const response = await launchMachine(parsedMachineName);
        const machineId = response.data.machineId;
        toast.dismiss(firstToastId);

        toastSuccess(`HakMachine ${parsedMachineName} successfully launched!\nMachine ID: ${machineId}`)

        setMachines([...machines, { machineId, machineName: parsedMachineName, machineState: "running", machineAddress: "N/A" }]);
      } catch (err) {
        console.log(err);
      }
    },
    [machines]
  );

  const handleTerminateMachine = async (machineName: string) => {
    try {
      const firstToastId = toast("Terminating machine...")
      await terminateMachine(machineName);
      toast.dismiss(firstToastId);

      toastSuccess(`HakMachine ${machineName} successfully terminated!`)

      handleCloseTerminate()
    } catch (err) {
      console.log(err);
    }
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ContentContainer>
            <Typography component="h1" variant="h5">
              HakMachines
              <IconButton
                aria-label="refresh"
                onClick={() => handleRefresh()}
              >
                <SyncIcon sx={{ color: "white" }} />
              </IconButton>
            </Typography>

            <div
              className="justify-content-center w-25 mt-5"
              style={{ position: "relative", zIndex: 1, minWidth: "500px" }}
            >

              {loading ? (
                <CircularProgress color="primary" />) : (

                <List>
                  {machines && machines.map((machine) => (
                    <ListItem
                      key={`${machine.machineId}-delete`}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleOpenTerminate(machine.machineId)}
                          disabled={machine.machineState === "terminated" || machine.machineState === "shutting-down"}
                        >
                          {machine.machineState === "running" ? (
                            <PowerSettingsNewIcon sx={{ color: "green" }} />
                          ) : (
                            <PowerSettingsNewIcon sx={{ color: "#" }} />
                          )}
                        </IconButton>
                      }
                    >
                      <Dialog
                      key={`${machine.machineId}-dialog`}
                      open={openTerminateId === machine.machineId}
                      onClose={handleCloseTerminate}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {`Terminate ${machine.machineName}?`}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to terminate this machine? This action cannot be undone.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseTerminate}>Cancel</Button>
                        <Button onClick={() => handleTerminateMachine(machine.machineName)} autoFocus>
                          Terminate
                        </Button>
                      </DialogActions>
                    </Dialog>
                      <ListItem
                        key={`${machine.machineId}-description`}
                        className="justify-content-center"
                        divider
                      >
                        <ListItemText
                          primary={<Typography fontWeight={"bold"} className="mb-2" sx={{ color: "white" }}>{machine.machineName} ({machine.machineId})</Typography>}
                          secondary={<Typography sx={{ color: "white" }} fontSize={"small"} >IP: {machine.machineAddress}</Typography>}
                        >
                        </ListItemText>
                      </ListItem>
                    </ListItem>
                  ))}
                  <ListItemButton
                    key={"more"} className="justify-content-center"
                    onClick={handleOpen}
                  >
                    <ListItemIcon>
                      <AddIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                  </ListItemButton>
                </List>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Form formMethods={formMethods} onSubmit={onSubmit}>
                    <InputText label="Machine Name" name="machine_name" type={"text"} required fullWidth />
                    <InputLaunchSubmit title="Launch new HakMachine!" sx={{ color: "#282A36", marginTop: 2 }}  />
                  </Form>
                </Box>
              </Modal>

            </div>
          </ContentContainer>
        </Box>
      </Container>
    </>
  );
};

export default HakMachines;
