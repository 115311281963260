import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import "./style.css";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Home as HomeIcon,
  Radar as RadarIcon,
  DeveloperBoard as DeveloperBoardIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";

const Sidebar: React.FC<any> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            HakEngine
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 20,
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/home");
              setOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              navigate("/scans");
              setOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <RadarIcon />
              </ListItemIcon>
              <ListItemText primary={"Scans"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              navigate("/hakmachines");
              setOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <DeveloperBoardIcon />
              </ListItemIcon>
              <ListItemText primary={"HakMachines"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              Auth.signOut().then(() => navigate("/"));
              setOpen(false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign-out"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <main
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: 64,
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        {children}
      </main>
    </Box>
  );
};

export default Sidebar;
