import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { getServices } from "../../hooks/Services";
import { useNavigate, useParams } from "react-router-dom";
import { IService } from "../../interfaces/services";

import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import ContentContainer from "../../common/ContentContainer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// interface ISubItems {
//   : ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

// interface ISubResults {
//   Items: ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

const Services: React.FC = () => {
  const [services, setServices] = useState<IService[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { scanId, subdomain } = useParams<{
    scanId: string;
    subdomain: string;
  }>();

  useEffect(() => {
    setLoading(true);

    const fetchScans = async () => {
      if (!scanId || !subdomain) return;
      const response = await getServices(scanId, subdomain);

      if (response.data.Items.length > 0) {
        setServices(JSON.parse(response.data.Items[0].Services));
      }
    };
    fetchScans().catch((err) => console.log(err));

    setLoading(false);
  }, [scanId, subdomain]);

  const goToService = (port: number) => {
    navigate(`/scans/${scanId}/${subdomain}/${port}/infos`);
  };

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    if (!scanId || !subdomain) return;
    const response = await getServices(scanId, subdomain);
    if (response.data.Items.length > 0) {
      setServices(JSON.parse(response.data.Items[0].Services));
    }

    setLoading(false);
  }, [scanId, subdomain]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContentContainer>
          <Typography component="h1" variant="h5">
            Services of {subdomain}:
            <IconButton aria-label="refresh" onClick={() => handleRefresh()}>
              <SyncIcon sx={{ color: "white" }} />
            </IconButton>
          </Typography>

          <div
            className="justify-content-center w-25 mt-5"
            style={{ position: "relative", zIndex: 1, minWidth: "500px" }}
          >
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <List dense>
                {services.length === 0 && (
                  <Typography variant="h6" align="center">
                    No services found
                  </Typography>
                )}
                {services &&
                  services.map((service) => (
                    <ListItemButton
                      key={service.port.toString()}
                      className="justify-content-center"
                      divider
                      onClick={() => goToService(service.port)}
                    >
                      <ListItemText>
                        <Typography align="center">
                          <b>Port:</b> {service.port.toString()}
                        </Typography>
                        <Typography align="center">
                          <b>Service:</b> {service.service}
                        </Typography>
                        {service.version && (
                          <Typography align="center">
                            <b>Version:</b> {service.version}
                          </Typography>
                        )}
                      </ListItemText>
                    </ListItemButton>
                  ))}
              </List>
            )}
          </div>
        </ContentContainer>
      </Box>
    </Container>
  );
};

export default Services;
