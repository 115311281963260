import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RecursiveDialog, { ContentItem } from "../RecursiveDialog";

interface Props {
  open: boolean;
  onClose: () => void;
  vulnerability?: ContentItem;
  vulnerabilityName: string;
}

const VulnerabilityDialog: React.FC<Props> = ({
  open,
  onClose,
  vulnerability,
  vulnerabilityName
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      {!!vulnerability && (
        <>
          <DialogTitle>{`Vulnerability: ${vulnerabilityName}`}</DialogTitle>

          <DialogContent>
            <RecursiveDialog
              onClose={() => {}}
              open={true}
              content={vulnerability as any}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default VulnerabilityDialog;
