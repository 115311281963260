import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoginForm from "./LoginForm";
import UpdateInformationForm from "./UpdateInformationForm";
import logo from "../../assets/logo.png";
import { Typography } from "@mui/material";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [pendingUser, setPendingUser] = useState<any>();

  useEffect(() => {
    async function load() {
      try {
        await Auth.currentAuthenticatedUser();
        navigate("/home", {
          replace: true,
        });
      } catch (error) {}
    }
    load();
  }, [navigate]);

  return (
    <main
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <img
          src={logo}
          style={{
            width: "100px",
            objectFit: "contain",
          }}
          alt="HakEngine's Logo"
        />

        <Typography variant="subtitle2" align="center">
          HakEngine
        </Typography>
      </div>

      {!pendingUser && <LoginForm setPendingUser={setPendingUser} />}
      {!!pendingUser && <UpdateInformationForm user={pendingUser} />}
    </main>
  );
};

export default SignIn;
