import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Button from '@mui/material/Button';

interface Props {
  title: string;
  [x:string]: any;
}

const InputLaunchSubmit: FC<Props> = ({ title, ...props }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button variant="contained" fullWidth type="submit" disabled={isSubmitting} {...props}>
      {title}
    </Button>
  );
};

export default InputLaunchSubmit;