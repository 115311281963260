import Api from "../services/Api";
import { getAuthHeader } from "../services/GetAuth";

export const getInfos = async (scanId: string, subdomain: string, port: number) => {
  const config = await getAuthHeader();

  return Api.get(`/infos?scanId=${scanId}&subdomain=${subdomain}&port=${port.toString()}`, config);
}

export const getVulns = async (scanId: string, subdomain: string, port: number) => {
  const config = await getAuthHeader();
  return Api.get(`/vulns?scanId=${scanId}&subdomain=${subdomain}&port=${port.toString()}`, config);
}