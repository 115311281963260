import TextField from "@mui/material/TextField";
import { FC, HTMLInputTypeAttribute } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  options?: RegisterOptions<any, any>;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  [x:string]: any;
}

const InputText: FC<Props> = ({
  name,
  label,
  options,
  placeholder,
  type = "text",
  ...props
}) => {
  const { register } = useFormContext();

  return (
    <TextField
      {...register(name, options)}
      className="form-control"
      placeholder={placeholder}
      defaultValue={""}
      type={type}
      {...props}
      label={label}
    />
  );
};

export default InputText;