import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  setPendingUser: Dispatch<SetStateAction<any>>;
}

const LoginForm: React.FC<Props> = ({ setPendingUser }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    async function load() {
      try {
        await Auth.currentAuthenticatedUser();
        navigate("/home", {
          replace: true,
        });
      } catch (error) {}
    }
    load();
  }, [navigate]);

  const { isLoading, mutate } = useMutation(async () => {
    const user = await Auth.signIn(username.trim(), password);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      setPendingUser(user);
    } else {
      navigate("/home", {
        replace: true,
      });
    }
  });

  return (
    <Paper
      style={{
        margin: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: 20,
      }}
    >
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate();
        }}
      >
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value || "")}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value || "")}
          />
        </FormControl>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={isLoading}
            loadingPosition="end"
          >
            Sign in
          </LoadingButton>
        </div>
      </form>
    </Paper>
  );
};

export default LoginForm;
