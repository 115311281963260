import React, { FC } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  title: string;
  [x:string]: any;
}

const InputSubmit: FC<Props> = ({ title, ...props }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <button
      type="submit"
      className="btn btn-primary w-100"
      disabled={isSubmitting}
      {...props}
    >
      {title}
    </button>
  );
};

export default InputSubmit;