import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { getSubs } from "../../hooks/Subs";
import { useNavigate, useParams } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import ContentContainer from "../../common/ContentContainer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ContentCopy, CopyAll, CopyAllOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

// interface ISubItems {
//   : ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

// interface ISubResults {
//   Items: ISub[];
//   Count?: number;
//   ScannedCount?: number;
// }

const Subs: React.FC = () => {
  const [subs, setSubs] = useState<string[]>([]);
  const [subsList, setSubsList] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { scanId } = useParams<{ scanId: string }>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const fetchScans = async () => {
      if (!scanId) return;

      const response = await getSubs(scanId);

      if (response.data.Items.length > 0) {
        setSubs(JSON.parse(response.data.Items[0].Subdomains));
      }
    };
    fetchScans().catch((err) => console.log(err));

    setLoading(false);
  }, [scanId]);

  const goToSub = (subdomain: string) => {
    navigate(`/scans/${scanId}/${subdomain}/services`);
  };

  const handleRefresh = useCallback(async () => {
    setLoading(true);
    if (!scanId) return;

    const response = await getSubs(scanId);
    if (response.data.Items.length > 0) {
      setSubs(JSON.parse(response.data.Items[0].Subdomains));
    }

    setLoading(false);
  }, [scanId]);

  const handleOpenCopyDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCopySubs = useCallback(() => {
    console.log("copying subs");

    convertSubdomainsToList();
    handleCopy(subsList || "");
  }, [subsList]);

  const handleCopy = useCallback((content: string) => {
    navigator.clipboard.writeText(content);
  }, []);

  const convertSubdomainsToList = useCallback(() => {
    // merge subs to one string with typography
    let newSubsList = "";
    subs.forEach((sub) => {
      newSubsList += `${sub}${'\n'}`;
    });

    setSubsList(newSubsList);
  }, [subs]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ContentContainer>
          <Typography component="h1" variant="h5">
            Subdomains of {scanId}:
            <IconButton aria-label="refresh" onClick={() => handleRefresh()}>
              <SyncIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton aria-label="copy" onClick={() => handleOpenCopyDialog()}>
              <ContentCopy sx={{ color: "white" }} />
            </IconButton>
          </Typography>

          <div
            className="justify-content-center w-25 mt-5"
            style={{ position: "relative", zIndex: 1, minWidth: "500px" }}
          >
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <List>
                {subs.length === 0 && (
                  <Typography variant="h6" align="center">
                    No subdomains found
                  </Typography>
                )}
                {subs &&
                  subs.map((sub) => (
                    <ListItemButton
                      key={sub}
                      className="justify-content-center"
                      divider
                      onClick={() => goToSub(sub)}
                    >
                      <ListItemText>
                        <Typography align="center">{sub}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  ))}
              </List>
            )}
          </div>
        </ContentContainer>

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md">
          <>
            <DialogTitle>
              {"List of subdomains:"}
              <IconButton aria-label="copy" onClick={() => handleCopySubs()}>
                <ContentCopy sx={{ color: "white" }} />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Box component="div" sx={{ whiteSpace: 'normal' }} justifyContent="flex-start">
                {subs.map((sub) => (
                  <Typography align="left" key={sub}>
                    {sub}
                  </Typography>
                ))}
              </Box>

            </DialogContent>
          </>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Subs;
