import { Paper } from "@mui/material";
import React from "react";

const ContentContainer: React.FC<any> = ({ children }) => {
  return (
    <Paper
      style={{
        margin: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: 20,
      }}
    >
      {children}
    </Paper>
  );
};

export default ContentContainer;
