import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import NestedItem from "../NestedItem";

export interface ContentItem {
  [key: string]: unknown;
}

interface Props {
  open: boolean;
  onClose: () => void;
  content: ContentItem;
}

const RecursiveDialog: React.FC<Props> = ({ open, onClose, content }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const items = useMemo(() => {
    if (!content) return [];

    return Object.keys(content)
      .filter((key) => !!content[key])
      .map((key: string) => {
        return {
          name: key,
          content: content[key],
        };
      });
  }, [content]);

  return (
    <div>
      {items.map((item) => {
        const summary =
          typeof item.content === "string"
            ? item.content.length > 60
              ? `${item.content.slice(0, 50)}...`
              : item.content
            : "";

        const needsExpand = !(
          typeof item.content === "string" && item.content.length <= 60
        );

        return (
          <Accordion
          key={`${item.name}-accordion`}
            expanded={needsExpand ? expanded === item.name : false}
            onChange={handleChange(item.name)}
          >
            <AccordionSummary
              expandIcon={needsExpand ? <ExpandMoreIcon /> : <></>}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{
                cursor: needsExpand ? "pointer" : "default",
              }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {item.name}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <NestedItem key={`${item.name}-nestedItem`} content={item.content} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default RecursiveDialog;
